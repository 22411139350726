<template>
    <div class="info-set">
        <div class="avatar">
            <van-image class="my-image" fit="cover" :src="getImg(userInfo.picHeadUrl)" alt="">
                <template v-slot:error><van-image class="my-image" :src="avatar" fit="cover" alt=""/></template>
            </van-image>
            <van-uploader accept="image/*" v-model="fileList" :after-read="afterRead">
                <div class="name">
                    更换头像
                </div>
            </van-uploader>
        </div>

        <!-- <van-cell-group class="my-group">
           
        </van-cell-group> -->

        <van-cell-group class="my-group">
            <van-cell class="real-name"  title="我的信息" icon="idcard" to="center/realname" is-link/>
            <!-- <van-cell class="address" title="我的收货地址" icon="location-o" to="center/address" is-link/> -->
            <van-cell class="qr" title="我的二维码" icon="qr" to='/center/myqr' is-link/>
        </van-cell-group>
        <!-- <my-btn :btnStr="'退出'" @onSubmit="onSubmit"></my-btn> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            fileList: [],
            userInfo: {},
            avatar: require('@/assets/img/avatar.jpeg'),
        }
    },
    methods:{
        getMemberUser() { 
            this.$help.loading()
            this.$store.dispatch('getMemberUser').then(() => {
                this.userInfo = this.$store.state.memberUser
                //console.log(this.getImg(this.userInfo.picHeadUrl))
            })
            .finally(this.$help.loaded)
        },
        afterRead(file) {
            file.status = 'uploading'
            file.message = '上传中'
            let formData = new FormData();
            formData.append("file",file.file,file.file.name);

            if (this.type) {
                formData.append("fileType", this.type);
            }
            this.$help.loading()
            this.$api.post('member/sys/attachment/attachmentUpload', formData, {'Content-Type': 'multipart/form-data'})
            .then(response => {
                this.$api.post('member/base/member/member-update', {
                    id: this.userInfo.id,
                    picHeadUrl: response.id
                })
                    .then(res => {
                        if(res.data) {
                            this.$help.success('修改成功')
                            this.userInfo.picHeadUrl = response.id
                        }else{
                            this.$help.error(res.msg)
                        }
                    })
                // console.log(response.id)
            })
            .catch(() => {
                file.status = 'failed'
                file.message = '上传失败'
            })
            .finally(this.$help.loaded)
            
        },
        onSubmit(){
            this.$help.confirm({
                title: '提示',
                message: '确定退出吗',
            })
            .then(() => {
                this.$router.push({path:'/login'})
                this.$help.token.remove()
            })
            .catch(() => {})
        }
    },
    mounted() {
        this.getMemberUser()
        
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .info-set{
        //background-color: $backgroundColor;
        .avatar{
            padding: 20px 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .van-uploader__preview{
                display: none;
            }
            .my-image{
                height: 80px;
                width: 80px;
                border-radius: 50%;
                img{
                    border-radius: 50%;
                }
                .van-image__error{
                    border-radius: 50%;
                }
            }
            .name{
                line-height: 40px;
                font-size: 14px;
                color: $main;
            }
        }
        .my-group{
            margin-bottom: 15px;
            .real-name{
                .van-cell__left-icon{
                    color: $yellow;
                }
            }  
             .qr{
                .van-cell__left-icon{
                    color: $main;
                }
            }
            .address{
                .van-cell__left-icon{
                    color: $pink;
                }
            }
           
        }
    }

</style>